<template lang="pug">
  v-container
    v-toolbar(
      color="primary"
      dark
      dense
      flat
    )
      span Monitoring
    v-data-table(
      :items="temperatures"
      :headers="headers"
      :search="search"
      dense
      :items-per-page="-1"
      hide-default-footer
      sort-by="name"
      must-sort
      :loading="!temperatures.length"
    )
      template(v-slot:top)
        v-row
          v-col(cols="12" lg="3" md="3")
            v-text-field(
              label="search"
              append-icon="mdi-magnify"
              v-model="search"
            )
      template(v-slot:item.name="{ value, item }")
        v-btn(
          text
          block
          @click="$router.push(`/incubators/temperatures/${value}/show`)"
        ).primary--text
          v-icon(v-if="!item.live" color="red") mdi-exclamation
          strong {{ value }}
      template(v-slot:item.heater="{ value }")
        div.span-expand.pa-2(:class="heaterClass(value)" v-if="value && value > 0") {{ value.celsiusFormat() }}
        span.span-expand.blue-grey.darken-3(v-else)
      template(v-slot:item.fan1="{ value }")
        span.span-expand.pa-2(:class="fanTempClass(value)" v-if="value && value > 0") {{ value.celsiusFormat() }}
        span.span-expand.blue-grey.darken-3(v-else)
      template(v-slot:item.fan2="{ value }")
        span.span-expand.pa-2(:class="fanTempClass(value)" v-if="value && value > 0") {{ value.celsiusFormat() }}
        span.span-expand.blue-grey.darken-3(v-else)
      template(v-slot:item.fan3="{ value }")
        span.span-expand.pa-2(:class="fanTempClass(value)" v-if="value && value > 0") {{ value.celsiusFormat() }}
        span.span-expand.blue-grey.darken-3(v-else)
</template>
<style lang="sass" scoped>
  .transition-1
    transition-delay: 1s
  .span-expand
    display: block
    width: 100%
    height: 100%
</style>
<script>
const defaultTemps = () => {
  const boxes = ['A', 'B', 'C', 'D']
  const list = []
  for (let i = 0; i < 7; i++) {
    list.push(boxes.map(item => ({
      name: `${i + 1}${item}`,
      heater: 0,
      fan1: 0,
      fan2: 0,
      fan3: 0,
      live: false,
    })))
  }
  return [].concat(...list)
}

const tableVars = () => ({
  headers: [
    { divider: true, sortable: true, text: 'Inc', cellClass: 'pa-0', value: 'name', align: 'center' },
    { divider: true, sortable: false, text: 'Heater', cellClass: 'pa-0', filterable: false, value: 'heater', align: 'center' },
    { divider: true, sortable: false, text: 'Fan 1', cellClass: 'pa-0', filterable: false, value: 'fan1', align: 'center' },
    { divider: true, sortable: false, text: 'Fan 2', cellClass: 'pa-0', filterable: false, value: 'fan2', align: 'center' },
    { divider: true, sortable: false, text: 'Fan 3', cellClass: 'pa-0', filterable: false, value: 'fan3', align: 'center' },
  ],
  search: null,
})

export default {
  name: 'Temperature',
  data () {
    return {
      ...tableVars(),
      temperatures: process.env.NODE_ENV === 'production' ? [] : [
        { name: '1A', heater: 39.5, fan1: 38.1, fan2: 36.7, fan3: 34.1 },
      ],
    }
  },
  created () {
    this.temperatures = defaultTemps()
    this.initWebsocket()
  },
  methods: {
    initWebsocket () {
      const { echo } = this.$store.state.websocket
      echo.private('stream.event')
        .listen('IncubatorTemperatureLiveEvent', ({ data }) => {
          const temp = data
          this.temperatures = this.temperatures.map(item => {
            const index = temp.findIndex(t => t.name === item.name)
            if (index === -1) return Object.assign({ ...item, live: false })
            const tempData = temp.splice(index, 1)[0]
            return Object.assign({ ...tempData })
          })
        })
    },
    heaterClass (input) {
      const temp = Math.round(input)
      const getBackgroundColor = () => {
        if (temp > 42) return 'red'
        if (temp > 40) return 'yellow darken-3'
        if (temp > 38) return 'green'
        return 'blue-grey' 
      }
      return [getBackgroundColor()]
    },
    fanTempClass (input) {
      const temp = Math.round(input)
      const getBackgroundColor = () => {
        if (temp > 40) return 'red'
        if (temp > 38) return 'yellow darken-3'
        if (temp > 36) return 'green'
        return 'blue-grey' 
      }
      return [getBackgroundColor()]
    },
  },
}
</script>